import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getCookie } from 'cookies-next';

import { ACCESS_TOKEN } from '@/app/config/cookies';
import { QUERY_KEYS } from '@/app/config/query';
import $api from '@/app/lib/api';
import { QueryArgs } from '@/app/shared-composables/useQueries/types';
import { GetParams, GetQueryParams, GetReturnType } from '@/app/shared-composables/useQueries/user/me/types';

type Args = QueryArgs<GetQueryParams, GetParams, GetReturnType>;

export async function queryFn ({ signal }: QueryFunctionContext, queryParams?: GetQueryParams, params?: GetParams) {
    try {
        if (!getCookie(ACCESS_TOKEN)) return null;
        const response = await $api.get<GetReturnType>('/user/me', { signal, params: queryParams });

        // response.data.role = 'ADMIN'; // fix
        return response.data || null;
    } catch (e) {
        return null;
    }
}

export function useUserInfo (options?: Args['options'], queryParams?: Args['queryParams'], params?: Args['params']) {
    return useQuery<GetReturnType>({
        queryKey: [QUERY_KEYS.USER.ME.GET],
        queryFn: context => queryFn(context, queryParams, params) as any,
        refetchOnWindowFocus: true,
        ...(options || {})
    });
}
