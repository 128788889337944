'use client';

import { Anchor, Button, Center, Grid, SimpleGrid } from '@mantine/core';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next-nprogress-bar';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { checkIsMatchedRoute } from '@/app/config/router';
import BaseFormItem from '@/app/shared-components/base-form-item';
import { useLogin } from '@/app/shared-composables/useMutations/auth/login/post';
import { useUserInfo } from '@/app/shared-composables/useQueries/user/me/get';
import useValidators from '@/app/shared-composables/useValidators';
import { FormItem } from '@/app/types/shared';

import styles from './index.module.scss';

interface SignInForm {
    email: string
    password: string
    rememberMe: boolean
}

export default function Login () {
    const t = useTranslations('login');
    const { email, required } = useValidators();
    const router = useRouter();
    const pathname = usePathname();
    const userQuery = useUserInfo();
    const { control, handleSubmit, formState, reset } = useForm<SignInForm>({
        defaultValues: {
            email: '',
            password: '',
            rememberMe: false
        },
        shouldFocusError: true,
        mode: 'onTouched'
    });

    const loginMutation = useLogin({
        onSuccess: async () => {
            const userQueryData = await userQuery.refetch();
            const redirectTo = checkIsMatchedRoute({ pathname, user: userQueryData.data });

            if (redirectTo) {
                router.replace(redirectTo.path);
            }
        }
    });

    const onSubmit: SubmitHandler<SignInForm> = async data => {
        const { email, password, rememberMe } = data;

        try {
            await loginMutation.mutateAsync({
                email,
                password,
                rememberMe,
                _meta: {
                    toast: {
                        error: {}
                    }
                }
            });
            reset();
        } catch (e) {}
    };

    const fields = useMemo(() => {
        const fieldsOrder: Array<FormItem<keyof SignInForm>> = [
            {
                type: 'textInput',
                gridColProps: {
                    span: 2
                },
                name: 'email',
                label: t('form.fields.email.label'),
                withAsterisk: true,
                placeholder: t('form.fields.email.placeholder'),
                rules: {
                    validate: {
                        required: (value: string) => required({ value }),
                        email: (value: string) => email({ value })
                    }
                }
            },
            {
                type: 'passwordInput',
                name: 'password',
                label: t('form.fields.password.label'),
                gridColProps: {
                    span: 2
                },
                withAsterisk: true,
                placeholder: t('form.fields.password.placeholder'),
                rules: {
                    validate: {
                        required: (value: string) => required({ value })
                    }
                }
            },
            {
                type: 'checkbox',
                gridColProps: {
                    span: 1
                },
                className: styles.rememberMe,
                name: 'rememberMe',
                label: t('form.fields.rememberMe.label')
            }
        ];

        return [...fieldsOrder.map(({ gridColProps, ...fieldItem }) => (
            <Grid.Col span={gridColProps?.span || 1} key={fieldItem.name}>
                <BaseFormItem
                    formItem={fieldItem}
                    control={control as any}
                />
            </Grid.Col>
        )), (
            <Grid.Col span={1} key="forgot-password">
                <div className={styles.forgotPasswordLinkWrapper}>
                    <Anchor className={styles.forgotPasswordLink} component={Link} href="/reset-password" size="xs">
                        {t('forgotPassword')}
                    </Anchor>
                </div>
            </Grid.Col>
        )];
    }, [control]);

    return (
        <SimpleGrid
            className={styles.root}
            cols={{ base: 1, md: 2 }}
        >
            <div className={styles.leftSide}>
                <Image
                    style={{ objectFit: 'cover' }}
                    priority
                    src="/login/bg.svg"
                    fill
                    alt=""
                />
                <Image className={styles.leftSideLogo} src="/shared/logo.svg" alt="logo" width={202} height={68}/>
            </div>
            <div className={styles.rightSide}>
                <Center h="100%">
                    <div className={styles.rightSideInner}>
                        <h2 className={styles.rightSideTitle}>
                            {t('rightSideTitle')}
                        </h2>
                        <p className={styles.rightSideHint}>
                            {t('rightSideHint')}
                        </p>
                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            <Grid columns={2} className={styles.formGrid}>
                                {...fields}
                            </Grid>
                            <Button
                                className="dl-button"
                                type="submit"
                                fullWidth
                                radius="md"
                                loading={formState.isSubmitting || loginMutation.isPending}
                            >
                                {t('submitBtn')}
                            </Button>
                        </form>
                    </div>
                </Center>
            </div>
        </SimpleGrid>
    );
}