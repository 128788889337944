import { useMutation } from '@tanstack/react-query';
import { setCookie } from 'cookies-next';

import { ACCESS_TOKEN, DEFAULT_ACCESS_TOKEN_LIFE, DEFAULT_REFRESH_TOKEN_LIFE, REFRESH_TOKEN } from '@/app/config/cookies';
import { MUTATION_KEYS } from '@/app/config/query';
import $api from '@/app/lib/api';
import type { PostParams, PostPayload, PostQueryParams, PostReturnType } from '@/app/shared-composables/useMutations/auth/login/types';
import { AllowedMutationOption } from '@/app/shared-composables/useMutations/types';

type Options = AllowedMutationOption<PostQueryParams, PostParams, PostReturnType, PostPayload>;

export function useLogin (options?: Options) {
    return useMutation({
        mutationKey: [MUTATION_KEYS.AUTH.SIGN_IN.POST],
        mutationFn: async ({ _params, _queryParams, ...payload }) => {
            const response = await $api.post<PostReturnType>('/auth/login', payload);
            const { accessToken, refreshToken } = response.data;

            setCookie(ACCESS_TOKEN, accessToken, { maxAge: payload.rememberMe ? DEFAULT_ACCESS_TOKEN_LIFE : undefined });
            setCookie(REFRESH_TOKEN, refreshToken, { maxAge: payload.rememberMe ? DEFAULT_REFRESH_TOKEN_LIFE : undefined });

            return response.data;
        },
        ...(options || {})
    });
}
